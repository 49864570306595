.languageSelector {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.language {
  width: 90px;
  height: 90px;
  background: fff;
  line-height: 22px;
  margin: 10px;
  display: flex;
  justify-content: center;
  padding-left: 8px;
  align-items: flex-start;
  flex-direction: column;
  border: 1px #ccc solid;
}

input[type="radio"] {
  visibility: hidden;
}

input[type="radio"]:checked ~ .language {
  border: 2px solid #333333;
}

.heading1 {
  font-size: 16px;
  font-weight: bold;
}

.heading2 {
  font-size: 14px;
  font-weight: normal;
}

.heading3 {
  font-size: 12px;
  font-weight: bold;
}
