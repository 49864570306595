.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #ffffff;
    color: black;
}

.accordion-title:hover {
    background-color: #ffffff;
}

.accordion-title,
.accordion-content {
    padding: 1rem;
}

.accordion-content {
    background-color: #ffffff;
}